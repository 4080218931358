import { CustomActions } from 'custom/constants/CustomConstants'


export function updateCustomData (state = {
  customData: null
}, action) {
  let data = Object.assign({}, state)
  switch ( action.type ) {
    case CustomActions.UPDATE_CUSTOM_DATA:
      data.customData = action.customData
      break
    case CustomActions.INITIAL_CUSTOM_DATA:
    case CustomActions.CLEAR_CUSTOM_DATA:
    default:
      return state
  }
  return data
}
