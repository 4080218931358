import React, { useContext, useEffect, useState } from 'react'
import { PageTitle, PageContent, PageSubContent, Spacer, MainHeader, DynamicImage } from 'components/styledComponents'
import { useIntl } from 'react-intl'
import { history } from 'main'
import './header.scss'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import useCheckLandscape from 'hooks/useCheckLandscape'
import { Clock } from 'components'
import useMedia from 'hooks/useMedia'
const Header = ({ headerText, isShowHeader = false }) => {
  const kioskInfo = useSelector((state) => state.kioskInfo)
  const airportCode = kioskInfo.airportCode
  const themeContext = useContext(ThemeContext)
  const isLandscape = useCheckLandscape()
  const intl = useIntl()
  const locale = useSelector((state) => state.localData.locale)
  const [titleText, setTitleText] = useState(intl.formatMessage(messages['BagdropTitle']))
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  useEffect(() => {
    if (messages[headerText]) {
      setTitleText(intl.formatMessage(messages[headerText]))
    } else {
      setTitleText(headerText)
    }
  }, [headerText, locale])

  return (
    <MainHeader height={themeContext.Header.height[ratioKey]} padding={themeContext.Header.padding[ratioKey]}>
      <PageContent flexFlow="column" width="100%" height="100%">
        <PageSubContent
          flexFlow={themeContext.Header.flexFlow ? themeContext.Header.flexFlow : 'row'}
          width="100%"
          padding="0"
        >
          <PageSubContent padding="0">
            <Clock locale={locale} width={themeContext.clockWidth[ratioKey]} />
          </PageSubContent>
          <PageContent>
            {isLandscape ? (
              <PageTitle
                fontSize={themeContext.Header.fontSize[ratioKey]}
                fontWeight={themeContext.Header.fontWeight}
                color={themeContext.Header.color}
                style={{ textTransform: themeContext.Header.uppercase ? 'uppercase' : '' }}
              >
                {titleText}
              </PageTitle>
            ) : (
              ''
            )}
          </PageContent>
          <PageSubContent padding="0">
            <DynamicImage
              imageName={themeContext.AirportLogo.logo}
              height={themeContext.AirportLogo.height[ratioKey]}
              width={themeContext.AirportLogo.width[ratioKey]}
              backgroundPosition={'center right'}
            />
          </PageSubContent>
        </PageSubContent>
        {isLandscape ? (
          ''
        ) : (
          <PageContent justifyContent="flex-end">
            <PageTitle
              fontSize={themeContext.Header.fontSize[ratioKey]}
              fontWeight="300"
              color={themeContext.Header.color}
              style={{
                textTransform: themeContext.Header.uppercase ? 'uppercase' : '',
                paddingLeft: themeContext.TextAlign === 'left' ? '40px' : 0
              }}
            >
              {titleText}
            </PageTitle>
          </PageContent>
        )}
      </PageContent>
    </MainHeader>
  )
}
export default Header
