import React, { useState } from 'react'
import { PageContent, PageTitle, PageSubContent } from 'components/styledComponents'
import { Button } from 'components'
import { history } from 'main'

const CustomPage = (props) => {
  return (
    <PageContent flexFlow="column">
      <PageTitle height="100px" color='black'>Custom Page</PageTitle>      
    </PageContent>
  )
}
export default CustomPage
