import { CustomActions } from 'custom/constants/CustomConstants'


export function updateCustomData (type, obj) {
  switch ( type ) {
    case 'updateCustomData':
      return {
        type: CustomActions.UPDATE_CUSTOM_DATA,
        customData: obj
      }
    case 'initialCustomData':
      return {
        type: CustomActions.INITIAL_CUSTOM_DATA
      }
    case 'clearCustomData':
      return {
        type: CustomActions.CLEAR_CUSTOM_DATA
      }
    default:

  }
}
